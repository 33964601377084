/* eslint-disable */
import { HTTP } from "@/config/http-constants";
import { API_LOADING, API_COMPLETED, API_ERROR } from "@/constants";
import * as ls from "@/config/local-storage-help";
import router from "@/router";
const $ls = ls;
const state = {
  user: ls.getLocalStorage() || {},
  token: ls.getToken() || "",
  status: API_COMPLETED,
  lockStatus: API_COMPLETED,
  resetPasswordStatus: API_COMPLETED,
  loginattempt: parseInt(localStorage.getItem("loginattempt")) || 0,
  ClientTempData: ls.getClientTempData()|| null,
  roles: [],
  error: "",
  searchProfession: "",
  searchLocation: { id: null, name: "" },
  paymentHash:  ls.getPaymentHash() || {},
  paymentStatus:ls.getPaymentStatus() || {},
  StepTempData: ls.getStepTempData()|| null,
  RegisterClientTempData: ls.getRegisterClientTempData() || null,
  RegisterClientCurrentStep: null,
  RegisterProfessionalTempData: ls.getRegisterProfessionalTempData() || null,
  RegisterProfessionalCurrentStep: null,
  phoneValidation: false
};

async function createClient(params = {}) {
  return HTTP.post(`/auth/register`, { ...params });
}

async function fetchLogin(params = {}) {
  return HTTP.post(`/auth/login`, { ...params });
}

async function ActivateAccount(params = {}) {
  return HTTP.post(`/auth/activate`, { ...params });
}

async function validatePhone(params = {}) {
  return HTTP.post(`/api/validate-phone`, { ...params });
}

async function resendActivateEmail(params = {}) {
  return HTTP.post(`/auth/send-activate`, { ...params });
}

async function resendActivatePhone(params = {}) {
  return HTTP.post(`/auth/send-activate-phone`, { ...params });
}

async function RecoverPassword(params = {}) {
  return HTTP.post(`/auth/reset-password`, { ...params });
}

async function forgotPasswordEmail(params = {}) {
  return HTTP.post(`/auth/forgot`, { ...params });
}

async function activateEmail(params = {}) {
  return HTTP.post(`/api/user/activate-by-admin`, { ...params });
}

async function SearchProfessionals(profession, location, services) {
  let url = `/public/professionals/search?profession=` + profession + `&location_id=` + location;
  if (services != "") {
    url =
      `/public/professionals/search?profession=` +
      profession +
      `&location_id=` +
      location +
      `&services=[` +
      services +
      `]`;
  }
  return HTTP.get(url);
}

async function GetProfileData(professionalId , professionId) {
  return HTTP.get(`/public/professionals/profile/` + professionalId +'?profession_id='+professionId);
}

async function GetLocations() {
  return HTTP.get(`/public/locations`);
}

async function GetClientData(clientId) {
  return HTTP.get(`/api/users/` + clientId);
}

async function GetUserServiceRequest(userId) {
  return HTTP.get(`/api/service-requests/` + userId);
}
async function GetUserServiceRequestByStatus(userId, requestStatus) {
  return HTTP.get(`/api/service-requests/` + userId + `?status=` + requestStatus);
}
async function GetUserBudgetRequest(userId, requestStatus) {
  return HTTP.get(`/api/budgets/` + userId + `?status=` + requestStatus);
}
async function GetServiceRequestDetails(token) {
  return HTTP.get(`/api/service-requests/details/` + token);
}

async function GetDepartments() {
  return HTTP.get(`/public/departments`);
}

async function GetDepartmentLocations(departmentId) {
  return HTTP.get(`public/locations?department_id=` + departmentId);
}

async function serviceRequest(params = {}) {
  return HTTP.post(`/api/service-requests/register`, { ...params });
}

async function updateClient(params = {}, userId) {
  return HTTP.put(`/api/users/update/` + userId, { ...params });
}
async function GetLocationDepartment(locationId) {
  return HTTP.get(`public/locations/` + locationId);
}
async function updateUserAvatar(formdata, userId) {
  return HTTP.post(`/api/users/update-avatar/` + userId, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function budgetRegister(formdata) {
  return HTTP.post(`/api/budgets/register`, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function serviceRegister(formdata) {
  return HTTP.post(`/api/service-requests/register`, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function registerProfessional(formdata) {
  return HTTP.post(`/auth/professionals/register`, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function submitProfessionalReview(params = {}) {
  return HTTP.post(`/api/professionals/review`, { ...params });
}
async function GetProfessionalReview(reviewStatus) {
  let url = `/api/professionals/review/all`;
  if (reviewStatus != "") {
    url = `/api/professionals/review/all?status=` + reviewStatus;
  }
  return HTTP.get(url);
}
async function UpdateProfessionalReview(id,rate,professionalServiceRate,description) {
  let url = `/api/dashboard/reviews`;
  return HTTP.put(url,{
    id,
    rate,
    professionalServiceRate,
    description
  });
}
async function updateReviewStatus(params = {}, reviewId) {
  return HTTP.put(`/api/professionals/review/update/` + reviewId, { ...params });
}
async function GetProfessional(professionalStatus) {
  let url = `/api/professionals/all`;
  if (professionalStatus != "") {
    url = `/api/professionals/all?status=` + professionalStatus;
  }
  return HTTP.get(url);
}
async function updateProfessionalStatus(params = {}, professionalId) {
  return HTTP.put(`/api/professionals/update/` + professionalId, { ...params });
}
async function resendProfessionalActivateWhatsapp( professionalId) {
  return HTTP.post(`/api/professionals/resend-activate-whatsapp/` + professionalId);
}
async function GetServices() {
  return HTTP.get(`/public/services`);
}
async function IsProfessionalExist(emailId) {
  return HTTP.get(`/public/users/check?email=` + emailId);
}
async function GetMessages() {
  return HTTP.get(`/api/messages`);
}
async function submitMessage(formdata) {
  return HTTP.post(`/api/messages`, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function GetMessageData(oid, otype) {
  return HTTP.get(`/api/messages/` + oid + `?type=` + otype);
}
async function readMessage(params = {}) {
  return HTTP.put(`/api/messages`, { ...params });
}
async function GetMessageRequestCount() {
  return HTTP.get(`/api/messages/count`);
}
async function GetMessagesCount(oid, otype) {
  return HTTP.get(`/api/messages/` + oid + `/count?type=` + otype);
}
async function socialLogin(params = {}) {
  return HTTP.post(`/auth/social-login`, { ...params });
}
async function socialLoginFacebook(params = {}) {
  return HTTP.post(`/auth/social-login/facebook`, { ...params });
}
async function socialLoginGoogle(params = {}) {
  return HTTP.post(`/auth/social-login/google`, { ...params });
}
async function GetProfessions(params) {
  if(params){
    return HTTP.get(`/public/professions?onlyActive=true `);
  }
  else {
    return HTTP.get(`/public/professions`);
  }
}
async function registerInterested(params = {}) {
  return HTTP.post(`/auth/register/interested`, { ...params });
}
async function getProfessionalInfo(tid) {
  return HTTP.get(`/public/professionals/` + tid);
}
async function getServiceByType(type) {
  return HTTP.get(`/public/services?type=` + type);
}

async function updateProfessional(formdata, token) {
  return HTTP.patch(`/auth/professionals/update/` + token, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function completeProfessionalProfile(formdata, token) {
  return HTTP.patch(`/auth/professionals/complete-profile/` + token, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function GetProfessionalData(userId) {
  return HTTP.get(`/public/professionals/profile/` + userId);
}
async function updateServicePrice(params = {}, professionalId) {
  return HTTP.put(`/api/professional-service/` + professionalId, { ...params });
}

async function updateProfessionalService(params = {}, professionalId) {
  return HTTP.patch(`/api/professional-service/update/` + professionalId, { ...params });
}
async function deleteProfessionService(userId, serviceId) {
  return HTTP.delete(`/api/professional-service/` + userId + `/remove-service/` + serviceId);
}
async function updateProfessionalPhotos(formdata, userId) {
  return HTTP.patch(`/api/professional-service/update-photo/` + userId, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function deleteProfessionPhoto(userId, photoId) {
  return HTTP.delete(`/api/professional-service/` + userId + `/remove-photo/` + photoId);
}
async function deleteProfessional(userId) {
  return HTTP.delete(`/api/professionals/` + userId );
}
async function updateServiceRequestStatus(params = {}, serviceId) {
  return HTTP.put(`/api/service-requests/update/` + serviceId, { ...params });
}
async function updatePaymentReciept(formdata) {
  return HTTP.put(`/api/payment/transfer`, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function GetTransferRequests() {
  return HTTP.get(`/api/service-requests/transfers/all`);
}
async function markAsPaid(serviceId) {
  return HTTP.put(`/api/service-requests/mark-as-paid/` + serviceId);
}
async function createPayment(params = {}) {
  return HTTP.post(`/api/payment/create`, { ...params });
}
async function getPaymentStatus(hash) {
  return HTTP.get(`/api/payment/` + hash);
}
async function addExtraService(params = {}, orderId) {
  return HTTP.post(`/api/service-requests-extra-service/` + orderId, { ...params });
}
async function removeExtraService(orderId, extraServiceId) {
  return HTTP.delete(`/api/service-requests-extra-service/` + orderId + `/remove/` + extraServiceId);
}
async function updateServiceRequest(params = {}, orderId) {
  return HTTP.put(`/api/service-requests/update-detail/` + orderId, { ...params });
}
async function updateServiceQuantity(params = {}, orderId,serviceDetailId) {
  return HTTP.put(`/api/service-requests/` + orderId + `/update-detail-quantity/` + serviceDetailId, { ...params });
}
async function GetServiceRequestData(oid, ostatus) {
  return HTTP.get(`/api/service-requests/` + oid + `?status=`+ostatus+`&id=` + oid);
}
async function updateBudgetRequestStatus(params = {}, budgetId) {
  return HTTP.put(`/api/budgets/update/` + budgetId, { ...params });
}
async function GetInvoiceData(orderId) {
  return HTTP.get(`/api/invoices/` + orderId);
}
async function updateInvoiceData(params = {}, orderId) {
  return HTTP.post(`/api/invoices/` + orderId, { ...params });
}
async function updateNotificationReadStatus(params = {}, notificationId) {
  return HTTP.put(`/api/notifications/update/` + notificationId, { ...params });
}
async function budgetRequest(formdata) {
  return HTTP.post(`/api/budgets-any/register`, formdata, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }
  });
}
async function updateProfessionalAvailability(params = {}, professionalId) {
  return HTTP.patch(`/api/professional-status/update/` + professionalId, { ...params });
}
async function validateProfessionalCertification(params = {}, documentId) {
  return HTTP.patch(`/api/professional-documents/validate/` + documentId, { ...params });
}
const actions = {
  async createClient({ commit }, params) {
    commit("loading");
    return createClient(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async fetchLogin({ commit }, params) {
    commit("loading");
    return fetchLogin(params)
      .then(response => {
        HTTP.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
        commit("fetchLogin", response.data);
        commit("success");
        commit("resetloginattempt");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("loginattempt");
        commit("error", err);
        throw Error(err);
      });
  },
  async logout({ commit }) {
    return new Promise((resolve, reject) => {
      commit("logout");
      delete HTTP.defaults.headers.common["Authorization"];
      resolve();
    }).catch(err => {
      commit("error", err);
      throw Error(err);
    });
  },
  async ActivateAccount({ commit }, params) {
    commit("loading");
    return ActivateAccount(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async validatePhone({ commit }, params) {
    commit("loading");
    return validatePhone(params)
      .then(response => {
        commit("success");
        commit("setPhoneValidation", false);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async RecoverPassword({ commit }, params) {
    commit("loading");
    return RecoverPassword(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async resendActivateEmail({ commit }, params) {
    commit("loading");
    return resendActivateEmail(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async resendActivatePhone({ commit }, params) {
    commit("loading");
    return resendActivatePhone(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async forgotPasswordEmail({ commit }, params) {
    commit("loading");
    return forgotPasswordEmail(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async activateEmail({ commit }, params) {
    commit("loading");
    return activateEmail(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async editUser({ commit }, params) {
    commit("loading");
    return updateUser(params)
      .then(response => {
        commit("updateUser", response.data);
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        toast.error(err);
        throw Error(err);
      });
  },
  async SearchProfessionals({ commit }, params) {
    commit("loading");
    return SearchProfessionals(params.profession, params.location, params.services)
      .then(response => {
        commit("success");
        commit("setSearchParameter", params);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetProfileData({ commit }, params) {
    commit("loading");
    return GetProfileData(params.professionalId ,params.professionId )
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async GetLocations({ commit }) {
    commit("loading");
    return GetLocations()
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async GetDepartments({ commit }) {
    commit("loading");
    return GetDepartments()
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async saveClientTempData({ commit }, params) {
    commit("saveClientTempData", params);
  },

  async removeClientTempData({ commit }) {
    commit("removeClientTempData");
  },

  async GetDepartmentLocations({ commit }, params) {
    commit("loading");
    return GetDepartmentLocations(params.departmentId);
  },

  async serviceRequest({ commit }, params) {
    commit("loading");
    return serviceRequest(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetClientData({ commit }, params) {
    commit("loading");
    return GetClientData(params.clientId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async budgetRegister({ commit }, formdata) {
    commit("loading");
    return budgetRegister(formdata)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async serviceRegister({ commit }, formdata) {
    commit("loading");
    return serviceRegister(formdata)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetUserServiceRequest({ commit }, params) {
    commit("loading");
    return GetUserServiceRequest(params.userId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetUserServiceRequestByStatus({ commit }, params) {
    commit("loading");
    return GetUserServiceRequestByStatus(params.userId, params.requestStatus)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetUserBudgetRequest({ commit }, params) {
    commit("loading");
    return GetUserBudgetRequest(params.userId, params.requestStatus)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async updateClient({ commit, state }, params) {
    commit("loading");
    return updateClient(params, params.userId)
      .then(response => {
        commit("success");

        const phoneNumber = `+${params.countryCallingCode}${params.phoneNumber}`;
        let localDataUpdate = {...params, phoneNumber}

        if (state.user.phoneNumber != phoneNumber) {
          localDataUpdate = {...params, phoneNumber: state.user.phoneNumber};
          commit("setPhoneValidation", true);
        }

        commit("updateLocalData", localDataUpdate);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetLocationDepartment({ commit }, params) {
    commit("loading");
    return GetLocationDepartment(params.locationId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateUserAvatar({ commit }, params) {
    commit("loading");
    return updateUserAvatar(params.formdata, params.userId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async registerProfessional({ commit }, formdata) {
    commit("loading");
    return registerProfessional(formdata)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetServiceRequestDetails({ commit }, params) {
    commit("loading");
    return GetServiceRequestDetails(params.token)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async submitProfessionalReview({ commit }, params) {
    commit("loading");
    return submitProfessionalReview(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async GetProfessionalReview({ commit }, params) {
    commit("loading");
    return GetProfessionalReview(params.reviewStatus)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async UpdateProfessionalReview({ commit }, params) {
    commit("loading");
    return UpdateProfessionalReview(params.id,params.rate,params.professionalServiceRate,params.description)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateReviewStatus({ commit }, params) {
    commit("loading");
    return updateReviewStatus(params.requestData, params.reviewId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetProfessional({ commit }, params) {
    commit("loading");
    return GetProfessional(params.professionalStatus)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateProfessionalStatus({ commit }, params) {
    commit("loading");
    return updateProfessionalStatus(params.requestData, params.professionalId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async resendProfessionalActivateWhatsapp({ commit }, professionalId) {
    commit("loading");
    return resendProfessionalActivateWhatsapp(professionalId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetServices({ commit }) {
    commit("loading");
    return GetServices()
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async IsProfessionalExist({ commit }, params) {
    commit("loading");
    return IsProfessionalExist(params.emailId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetMessages({ commit }) {
    commit("loading");
    return GetMessages()
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async submitMessage({ commit }, params) {
    commit("loading");
    return submitMessage(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetMessageData({ commit }, params) {
    commit("loading");
    return GetMessageData(params.id, params.type)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async readMessage({ commit }, params) {
    commit("loading");
    return readMessage(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetMessageRequestCount({ commit }) {
    commit("loading");
    return GetMessageRequestCount()
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetMessagesCount({ commit }, params) {
    commit("loading");
    return GetMessagesCount(params.id, params.type)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async socialLogin({ commit }, params) {
    commit("loading");
    return socialLogin(params)
      .then(response => {
        HTTP.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
        commit("fetchLogin", response.data);
        commit("success");

        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async socialLoginFacebook({ commit }, params) {
    commit("loading");
    return socialLoginFacebook(params)
      .then(response => {
        if (!response.data.register) {
          HTTP.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
          commit("fetchLogin", response.data);
          commit("success");
        }

        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async socialLoginGoogle({ commit }, params) {
    commit("loading");
    return socialLoginGoogle(params)
      .then(response => {
        if (!response.data.register) {
          HTTP.defaults.headers.common["Authorization"] = "Bearer " + response.data.token;
          commit("fetchLogin", response.data);
          commit("success");
        }

        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetProfessions({ commit },params) {
    commit("loading");
    return GetProfessions(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async registerInterested({ commit }, params) {
    commit("loading");
    return registerInterested(params)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async getProfessionalInfo({ commit }, params) {
    commit("loading");
    return getProfessionalInfo(params.tid)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async getServiceByType({ commit }, params) {
    commit("loading");
    return getServiceByType(params.type)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateProfessional({ commit }, params) {
    commit("loading");
    return updateProfessional(params.formdata, params.token)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async completeProfessionalProfile({ commit }, params) {
    commit("loading");
    return completeProfessionalProfile(params.formdata, params.token)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetProfessionalData({ commit }, params) {
    commit("loading");
    return GetProfessionalData(params.userId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateServicePrice({ commit }, params) {
    commit("loading");
    return updateServicePrice(params.requestData, params.professionalId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateProfessionalService({ commit }, params) {
    commit("loading");
    return updateProfessionalService(params.requestData, params.professionalId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateProfessionalPhotos({ commit }, params) {
    commit("loading");
    return updateProfessionalPhotos(params.formdata, params.userId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async deleteProfessionService({ commit }, params) {
    commit("loading");
    return deleteProfessionService(params.userId, params.serviceId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },

  async deleteProfessionPhoto({ commit }, params) {
    commit("loading");
    return deleteProfessionPhoto(params.userId, params.photoId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async deleteProfessional({ commit }, params) {
    commit("loading");
    return deleteProfessional(params.userId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateServiceRequestStatus({ commit }, params) {
    commit("loading");
    return updateServiceRequestStatus(params.requestData, params.serviceId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updatePaymentReciept({ commit }, params) {
    commit("loading");
    return updatePaymentReciept(params)
      .then(response => {
      commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async createPayment({ commit }, params) {
    commit("loading");
    return createPayment(params)
      .then(response => {
        response.data.orderId=params.orderId;
        commit('savePaymentHash',response.data);
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetTransferRequests({ commit }) {
    commit("loading");
    return GetTransferRequests()
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async getPaymentStatus({ commit }, params) {
    commit("loading");
    return getPaymentStatus(params.hash)
      .then(response => {
        commit("success");
        var paymentStatus={};
        paymentStatus.hash=params.hash;
        paymentStatus.status=response.data.paid;
        commit('setPaymentStatus',paymentStatus);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async markAsPaid({ commit }, params) {
    commit("loading");
    return markAsPaid(params.serviceId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
   },
  async removePaymentStatus({ commit }) {
    commit("removePaymentStatus");
  },
  async addExtraService({ commit }, params) {
    commit("loading");
    return addExtraService(params.requestData, params.orderId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async removeExtraService({ commit }, params) {
    commit("loading");
    return removeExtraService(params.orderId, params.extraServiceId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateServiceRequest({ commit }, params) {
    commit("loading");
    return updateServiceRequest(params.requestData, params.orderId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateServiceQuantity({ commit }, params) {
    commit("loading");
    return updateServiceQuantity(params.requestData, params.orderId,params.serviceDetailId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetServiceRequestData({ commit }, params) {
    commit("loading");
    return GetServiceRequestData(params.id, params.status)
 .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateBudgetRequestStatus({ commit }, params) {
    commit("loading");
    return updateBudgetRequestStatus(params.requestData, params.budgetId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async GetInvoiceData({ commit }, params) {
    commit("loading");
    return GetInvoiceData(params.orderId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateInvoiceData({ commit }, params) {
    commit("loading");
    return updateInvoiceData(params.requestData, params.orderId)
    .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async updateNotificationReadStatus({ commit }, params) {
    commit("loading");
    return updateNotificationReadStatus(params.requestData, params.notificationId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async budgetRequest({ commit }, formdata) {
    commit("loading");
    return budgetRequest(formdata)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async saveStepTempData({ commit }, params) {
    commit("saveStepTempData", params);
  },

  async removeStepTempData({ commit }) {
    commit("removeStepTempData");
  },
  async updateProfessionalAvailability({ commit }, params) {
    commit("loading");
    return updateProfessionalAvailability(params.requestData, params.professionalId)
      .then(response => {
        commit("success");
        commit("updateAvailability", params.requestData.isAvailable);
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  async validateProfessionalCertification({ commit }, params) {
    commit("loading");
    return validateProfessionalCertification(params.requestData, params.documentId)
      .then(response => {
        commit("success");
        return Promise.resolve(response.data);
      })
      .catch(err => {
        commit("error", err);
        throw Error(err);
      });
  },
  saveRegisterClientTempData({ commit }, params) {
    commit("saveRegisterClientTempData", params);
  },
  removeRegisterClientTempData({ commit }) {
    commit("removeRegisterClientTempData");
  },
  saveRegisterClientCurrentStep({ commit }, step) {
    commit("saveRegisterClientCurrentStep", step);
  },
  removeRegisterClientCurrentStep({ commit }) {
    commit("removeRegisterClientCurrentStep");
  },
  saveRegisterProfessionalTempData({ commit }, params) {
    commit("saveRegisterProfessionalTempData", params);
  },
  removeRegisterProfessionalTempData({ commit }) {
    commit("removeRegisterProfessionalTempData");
  },
  saveRegisterProfessionalCurrentStep({ commit }, step) {
    commit("saveRegisterProfessionalCurrentStep", step);
  },
  removeRegisterProfessionalCurrentStep({ commit }) {
    commit("removeRegisterProfessionalCurrentStep");
  },
  setPhoneValidation({ commit }, validate) {
    commit("setPhoneValidation", validate);
  },
};

const mutations = {
  fetchLogin(state, user) {
    let mutatedUser = Object.assign({}, user);
    mutatedUser.user_type = user.userType;
    state.user = mutatedUser.user;
    state.token = mutatedUser.token;
    $ls.setLocalStorage(user);
  },
  logout(state) {
    state.token = "";
    state.user = {};
    $ls.removeLocalStorage();
  },

  success(state) {
    state.status = API_COMPLETED;
  },
  loginattempt(state) {
    state.loginattempt = parseInt(state.loginattempt) + 1;
    localStorage.setItem("loginattempt", state.loginattempt);
  },
  resetloginattempt(state) {
    state.loginattempt = 0;
    localStorage.setItem("loginattempt", state.loginattempt);
  },
  error(state, error) {
    state.status = API_ERROR;
    state.error = error;
  },
  loading(state) {
    state.status = API_LOADING;
  },
  setSearchParameter(state, params) {
    state.searchProfession = params.profession;
    state.searchLocation = { id: params.location, name: params.locationname };
  },
  saveClientTempData(state, params) {
    state.ClientTempData = params;
    $ls.setClientTempData(params);
  },
  removeClientTempData(state) {
    state.ClientTempData = null;
    $ls.removeClientTempData();
  },
  savePaymentHash(state, hashData) {
    state.paymentHash = hashData;
    $ls.setPaymentHash(hashData);
  },
  setPaymentStatus(state, hashData) {
    hashData.orderId=state.paymentHash.orderId;
    state.paymentStatus = hashData;
    $ls.setPaymentStatus(hashData);
  },
  removePaymentStatus(state) {
    state.paymentStatus = {};
    $ls.removePaymentStatus();
  },
  saveStepTempData(state, params) {
    state.StepTempData = params;
    $ls.setStepTempData(params);
  },
  removeStepTempData(state) {
    state.StepTempData = null;
    $ls.removeStepTempData();
  },
  updateLocalData(state, data){
    let updatedUser={};
    if(data.phoneNumber) state.user.phoneNumber = data.phoneNumber;
    if(data.firstName) state.user.firstName = data.firstName;
    if(data.lastName) state.user.lastName = data.lastName;
    if(data.identificationNumber) state.user.identificationNumber = data.identificationNumber;
    if(data.address) state.user.address = data.address;
    if(data.location_id) state.user.location_id = data.location_id;
    if(data.ruc) state.user.ruc = data.ruc;
    updatedUser.user=state.user;
    updatedUser.token=state.token;
    $ls.setLocalStorage(updatedUser);
  },
  updateAvailability(state, isAvailable) {
    let updatedUser={};
    state.user.professionalProfile.isAvailable = isAvailable;
    updatedUser.user=state.user;
    updatedUser.token=state.token;
    $ls.setLocalStorage(updatedUser);
  },
  saveRegisterClientTempData(state, params) {
    state.RegisterClientTempData = params;
    $ls.setRegisterClientTempData(params);
  },
  removeRegisterClientTempData(state) {
    state.RegisterClientTempData = null;
    $ls.removeRegisterClientTempData();
  },
  saveRegisterClientCurrentStep(state, step) {
    state.RegisterClientCurrentStep = step;
    $ls.setRegisterClientCurrentStep(step);
  },
  removeRegisterClientCurrentStep(state) {
    state.RegisterClientCurrentStep = null;
    $ls.removeRegisterClientCurrentStep();
  },
  saveRegisterProfessionalTempData(state, params) {
    state.RegisterProfessionalTempData = params;
    $ls.setRegisterProfessionalTempData(params);
  },
  removeRegisterProfessionalTempData(state) {
    state.RegisterProfessionalTempData = null;
    $ls.removeRegisterProfessionalTempData();
  },
  saveRegisterProfessionalCurrentStep(state, step) {
    state.RegisterProfessionalCurrentStep = step;
    $ls.setRegisterProfessionalCurrentStep(step);
  },
  removeRegisterProfessionalCurrentStep(state) {
    state.RegisterProfessionalCurrentStep = null;
    $ls.removeRegisterProfessionalCurrentStep();
  },
  setPhoneValidation(state, validate) {
    state.phoneValidation = validate;
  },
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  user: state => state.user,
  error: state => state.error,
  loginattempt: state => state.loginattempt,
  searchProfession: state => state.searchProfession,
  searchLocation: state => state.searchLocation,
  paymentHash: state=>state.paymentHash,
  paymentStatus:state=>state.paymentStatus,
  ClientTempData:state=>state.ClientTempData,
  StepTempData:state=>state.StepTempData,
  RegisterClientTempData:state=>state.RegisterClientTempData,
  RegisterClientCurrentStep:state=>state.RegisterClientCurrentStep,
  RegisterProfessionalTempData:state=>state.RegisterProfessionalTempData,
  RegisterProfessionalCurrentStep:state=>state.RegisterProfessionalCurrentStep,
  phoneValidation:state=>state.phoneValidation
};

export default {
  state,
  getters,
  actions,
  mutations
};
