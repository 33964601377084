<template>
  <section class="px-2 lg:w-8/12">
    <Export :type="'professional'" />
    <SearchFilters @filters-updated="listProfessionals" />
    <div class="w-full flex justify-center mt-3">
      <i class="font-medium text-xl">{{ totalItems }} resultados en total</i>
    </div>
    <Paginator
      :currentPage="currentPage"
      :totalPages="totalPages"
      :onClickPage="listProfessionals"
      :showPrevBtn="showPrevBtn"
      :showNextBtn="showNextBtn"
    />
    <div class="w-full flex flex-col mt-3">
      <template v-for="(professional, i) in professionals">
        <div
          class="px-5 pt-5 mb-6 overflow-hidden bg-white border border-gray-400 rounded"
          :key="i"
        >
          <div :style="professional.active ? 'opacity:1' : 'opacity:.5'">
            <header
              class="flex items-center justify-between p-4 mt-0 -mx-5 -mt-5 font-semibold bg-white border-b border-gray-200"
            >
              <div class="flex flex-col lg:flex-row">
                <strong>Estado:</strong>
                <span
                  class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary info"
                  v-if="!professional.active"
                  >Inactivado</span
                >
                <span
                  class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
                  v-if="professional.status == 'accepted' && professional.active"
                  >Aceptado</span
                >
                <span
                  class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary"
                  v-if="professional.status == 'pending' && professional.active"
                  >Pendiente</span
                >
                <span
                  class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary error"
                  v-if="professional.status == 'rejected' && professional.active"
                  >Rechazado</span
                >
                <span
                  class="-mt-1 lg:mt-0 lg:pl-2 text-brand-secondary success"
                  v-if="professional.status == 'complete' && professional.active"
                  >Completado</span
                >
              </div>
              <div class="flex flex-col lg:flex-row">
                <router-link
                  :to="{
                    name: 'AdminEditProfessionalProfile',
                    params: { id: professional.userId },
                  }"
                  class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                  v-if="professional.status == 'complete'"
                >
                  Editar perfil
                </router-link>
                <router-link
                  :to="{
                    name: 'AdminEditProfessionalPhoto',
                    params: { id: professional.userId },
                  }"
                  class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success ml-2"
                  v-if="professional.status == 'complete'"
                >
                  Editar fotos
                </router-link>
                <!-- Ocultado por funcionamiento incorrecto  -->
                <!-- <button
                @click="($event) => deleteProfessional(professional.userId)"
                class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--danger ml-2"
              >
                Dar de baja
              </button> -->
              </div>
            </header>

            <div class="flex flex-col justify-between pt-4 lg:flex-row">
              <figure class="flex items-center lg:mb-0 lg:w-2/12 lg:mr-4 lg:flex-col">
                <img
                  :src="professional.pictureProfileUrl"
                  :alt="professional.firstName + ' ' + professional.lastName"
                  class="object-cover shadow avatar avatar--xl"
                />
                <figcaption
                  class="pl-2 text-2xl text-center text-gray-900 lg:text-lg lg:py-2 lg:pl-0 font-display"
                >
                  {{ professional.firstName }} {{ professional.lastName }}
                </figcaption>
              </figure>

              <hr class="my-3 -mx-5 border-gray-200 lg:hidden" />

              <div class="flex-1">
                <div class="flex flex-col -mx-2 lg:flex-row">
                  <div class="px-2 w-1/1 lg:w-1/3">
                    <h6 class="mb-0">Email:</h6>
                    <p>{{ professional.email }}</p>
                    <h6 class="mb-0">Celular:</h6>
                    <p>{{ professional.phoneNumber }}</p>
                    <div v-if="professional.status == 'accepted'" class="mb-2">
                      <button
                        class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                        @click="resendActivateWhatsapp(professional.userId)"
                        :disabled="processingResendActivateWhatsapp"
                      >
                        Reenviar Whatsapp
                      </button>
                    </div>
                    <h6 class="mb-0">RUC/C.I:</h6>
                    <p>{{ professional.identificationNumber }}</p>
                    <h6 class="mb-0">Servicio:</h6>
                    <ul class="mb-3 ml-8 list-disc">
                      <li v-for="(Service, index) in professional.Services" :key="index">
                        {{ Service.description }}
                      </li>
                    </ul>
                  </div>

                  <div class="px-2 w-1/1 lg:w-1/3">
                    <h6 class="mb-0">Dirección:</h6>
                    <p>{{ professional.address }}</p>
                    <h6 class="mb-2">Ciudad:</h6>
                    <p>{{ professional.Location.description }}</p>
                    <h6 class="mb-0">Profesiones:</h6>
                    <ul class="mb-3 ml-8 list-disc">
                      <li v-for="(item, index) in professional.Profession" :key="index">
                        {{ item.description }}
                      </li>
                    </ul>
                    <h6 class="mb-2">Otro oficio / categoría:</h6>
                    <p>{{ professional.otherProfession }}</p>
                    <h6 class="mb-3">Url de registro (2nda parte):</h6>
                    <ul class="mb-3 ml-8 list-disc">
                      <li>
                        <a :href="professional.registrationUrl" target="_blank">{{
                          professional.registrationUrl
                        }}</a>
                      </li>
                    </ul>
                    <h6 class="mb-0">Ubicación de zona laboral</h6>
                    <ul class="mb-3 ml-8 list-disc">
                      <li>
                        <a :href="professional.locationUrl" target="_blank">{{
                          professional.locationUrl
                        }}</a>
                      </li>
                    </ul>
                  </div>

                  <div class="px-2 w-1/1 lg:w-1/3">
                    <h6 class="mb-0">Referencia laboral 1:</h6>
                    <p>
                      {{ professional.laboralReferenceName1 }} <br />
                      {{ professional.laboralReferencePhone1 }}
                    </p>
                    <h6 class="mb-0">Referencia laboral 2:</h6>
                    <p>
                      {{ professional.laboralReferenceName2 }} <br />
                      {{ professional.laboralReferencePhone2 }}
                    </p>
                    <h6 class="mb-0">Fecha de Registro:</h6>
                    <p>
                      {{ professional.createdAt }}
                    </p>
                  </div>
                </div>

                <div class="flex gap-5">
                  <div class="flex flex-col">
                    <div>
                      <h6 class="mb-0">Descripción del servicio:</h6>
                      <p>{{ professional.coverLetter }}</p>
                    </div>
                    <div>
                      <h6>Adjuntar archivos:</h6>
                      <ul
                        class="mb-3 ml-8 list-disc"
                        v-if="professional.Files && professional.Files.length > 0"
                      >
                        <li v-for="(File, index) in professional.Files" :key="index">
                          <a :href="File.fileUrl" target="_blank">{{ File.description }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <h6>Certificados:</h6>
                    <ul
                      class="mb-3 ml-8 list-disc"
                      v-if="professional.Documents && professional.Documents.length > 0"
                    >
                      <li v-for="(Document, index) in professional.Documents" :key="index">
                        <a :href="Document.description" target="_blank">
                          {{ Document.profession }}
                        </a>
                        <div class="mb-2">
                          <button
                            class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success ml-2 !text-sm"
                            v-if="Document.status === 'pending'"
                            @click="validateProfessionalCertification(Document.id, 'accepted')"
                            :disabled="processingValidateCertification"
                          >
                            Validar
                          </button>
                          <button
                            class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--danger ml-2 !text-sm"
                            v-if="Document.status === 'pending'"
                            @click="validateProfessionalCertification(Document.id, 'rejected')"
                            :disabled="processingValidateCertification"
                          >
                            Rechazar
                          </button>
                          <button
                            class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success ml-2 !text-sm"
                            v-if="Document.status === 'accepted'"
                            @click="validateProfessionalCertification(Document.id, 'pending')"
                            :disabled="processingValidateCertification"
                          >
                            Revisión
                          </button>
                          <button
                            class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success ml-2 !text-sm"
                            v-if="Document.status === 'rejected'"
                            @click="validateProfessionalCertification(Document.id, 'pending')"
                            :disabled="processingValidateCertification"
                          >
                            Revisión
                          </button>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <footer
              class="relative flex flex-col items-center px-5 py-2 mt-3 -mx-5 text-xs bg-gray-100 border-t lg:flex-row bordera-gray-200"
            >
              <div class="pb-12 lg:pb-0 lg:pr-24">
                <span class="pr-1 text-brand-secondary">(*)</span>Aceptado la solicitud, el
                prefesional se pondrá en contacto con usted, para coordinar el servicio, habilitando
                el botón de pago una vez que haya concluido el servicio solicitado
              </div>
              <div
                class="absolute bottom-0 flex-none w-full lg:relative lg:w-auto"
                v-if="professional.status"
              >
                <button
                  class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--success"
                  @click="updateProfessionalStatus(professional.userId, 'accepted')"
                  v-if="professional.status == 'pending'"
                  :disabled="processingUpdateProfessional"
                >
                  Aceptar
                </button>
                <button
                  class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--danger ml-6"
                  @click="updateProfessionalStatus(professional.userId, 'rejected')"
                  v-if="professional.status == 'pending' || professional.status == 'complete'"
                  :disabled="processingUpdateProfessional"
                >
                  Rechazar
                </button>
                <button
                  class="w-full rounded-none lg:w-auto btn btn--md lg:rounded btn--secondary ml-6"
                  @click="updateProfessionalStatus(professional.userId, 'pending')"
                  v-if="professional.status == 'rejected'"
                  :disabled="processingUpdateProfessional"
                >
                  Revertir
                </button>
              </div>
            </footer>
          </div>
        </div>
      </template>
    </div>
    <Paginator
      :currentPage="currentPage"
      :totalPages="totalPages"
      :onClickPage="listProfessionals"
      :showPrevBtn="showPrevBtn"
      :showNextBtn="showNextBtn"
    />
  </section>
</template>

<script>
import Header from "@/components/Header.vue";
import Messages from "@/components/Messages.vue";
import Breadcrumb from "@/components/Breadcrumb.vue";
import AdminProfileSidebar from "@/components/AdminProfileSidebar.vue";
import { mapGetters } from "vuex";
import Footer from "@/components/Footer.vue";
import ProfessionalsRequestsList from "./List/index.vue";
import SearchFilters from "./SearchFilters.vue";
import { Professionals } from "@/services";
import statusTranslations from "@/utils/helpers/ProfessionalProfile/statusTranslations";
import Paginator from "@/components/Paginator.vue";
import Export from "../Export.vue";

export default {
  data() {
    return {
      processingGetProfessionals: false,
      processingUpdateProfessional: false,
      processingValidateCertification: false,
      processingResendActivateWhatsapp: false,
      professionalStatus: "pending",
      professionals: [],
      updateProfessionalData: {
        professionalId: null,
        requestData: {
          status: "",
        },
      },
      validateCertificationData: {
        documentId: null,
        requestData: {
          status: "",
        },
      },
      searchQuery: "",
      page: 1,
      currentPage: 1,
      totalPages: 0,
      showPrevBtn: false,
      showNextBtn: false,
      totalItems: 0,
      profileStates: Object.keys(statusTranslations),
    };
  },
  mounted() {
    this.$store.dispatch("setShowAlert", false);
    this.listProfessionals({});
  },
  methods: {
    listProfessionals({
      query = "",
      queryFilters = ["professional_fullname", "profession_name"],
      page = this.page,
      profileStates = this.profileStates,
      accountStates = ["active", "inactive"],
    }) {
      this.profileStates = profileStates;
      console.log(profileStates);
      console.log(this.profileStates);
      this.processingGetProfessionals = true;
      Professionals.requests({ query, queryFilters, page, profileStates, accountStates })
        .then(({ data }) => {
          this.professionals = data.professional_profiles.data;
          this.currentPage = data.professional_profiles.meta.page.current_page;
          this.totalPages = data.professional_profiles.meta.page.last_page;
          this.showPrevBtn = data.professional_profiles.meta.page.hasPreviousPage;
          this.showNextBtn = data.professional_profiles.meta.page.hasNextPage;
          this.totalItems = data.professional_profiles.meta.page.total;
        })
        .catch((error) => {
          this.professionals = [];
        })
        .finally(() => {
          this.processingGetProfessionals = false;
        });
    },
    deleteProfessional(professionalId) {
      this.$store
        .dispatch("deleteProfessional", { userId: professionalId })
        .then((response) => {
          this.listProfessionals({});
          let removeServiceMessage = response.msg;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", removeServiceMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
        });
    },
    updateProfessionalStatus(professionalId, status) {
      this.processingUpdateProfessional = true;
      this.updateProfessionalData.professionalId = professionalId;
      this.updateProfessionalData.requestData.status = status;
      this.$store
        .dispatch("updateProfessionalStatus", this.updateProfessionalData)
        .then(() => {
          this.processingUpdateProfessional = false;
          this.listProfessionals({});
          let registerMessage = `Professional Status Updated Successfully.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", registerMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingUpdateProfessional = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    resendActivateWhatsapp(professionalId) {
      this.processingResendActivateWhatsapp = true;

      this.$store
        .dispatch("resendProfessionalActivateWhatsapp", professionalId)
        .then(() => {
          this.processingResendActivateWhatsapp = false;
          let registerMessage = `Send Activate Whatsapp Successfully.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", registerMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingResendActivateWhatsapp = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
    validateProfessionalCertification(documentId, status) {
      this.processingUpdateProfessional = true;
      this.validateCertificationData.documentId = documentId;
      this.validateCertificationData.requestData.status = status;
      this.$store
        .dispatch("validateProfessionalCertification", this.validateCertificationData)
        .then(() => {
          this.processingUpdateProfessional = false;
          this.listProfessionals({});
          let registerMessage = `Certificación validada correctamente.`;
          this.$store.dispatch("setIsErrorAlert", false);
          this.$store.dispatch("setIsSuccessAlert", true);
          this.$store.dispatch("setGlobalMessage", registerMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        })
        .catch(() => {
          this.processingUpdateProfessional = false;
          let errorMessage = this.error.response.data.msg;
          this.$store.dispatch("setIsSuccessAlert", false);
          this.$store.dispatch("setIsErrorAlert", true);
          this.$store.dispatch("setGlobalMessage", errorMessage);
          this.$store.dispatch("setShowAlert", true);
          window.scrollTo({ top: 0, behavior: "smooth" });
        });
    },
  },

  computed: {
    ...mapGetters(["error", "user"]),
  },
  components: {
    Paginator,
    Header,
    Footer,
    Messages,
    Breadcrumb,
    AdminProfileSidebar,
    ProfessionalsRequestsList,
    SearchFilters,
    Export,
  },
};
</script>
<style lang="css" scoped>
@import "../../../assets/css/dashboard.css";
.success {
  color: #1bb01b;
}
.error {
  color: #e54d42;
}
.btn--danger,
.btn--danger:hover {
  background-color: red;
}
</style>
